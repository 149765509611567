<template>
    <b-modal :title="$t('general.details')" v-model="modalActive" no-close-on-backdrop hide-footer size="xl">
        <div v-for="item in items" :key="item.name" class="mb-4">
            <div class="mb-1">
                {{ item.name }} - {{ $numberRounding(item.price, 2) }} €
            </div>
            <CustomTable
                :fields="statusColumnDefs"
                :items="item.payment_types"
                :showTableOptions="false"
            />
        </div>
    </b-modal>
</template>
<script>
    import {BModal} from 'bootstrap-vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {
            BModal,
            CustomTable
        },
        data() {
            return {
                modalActive: false,
                items: []
            }
        },
        computed: {
            statusColumnDefs() {
                return [
                    {
                        key: 'name',
                        label: this.$t('keys.returnable_packaging.payment_type'),
                        filter: true,
                        permissions: true
                    },
                    {
                        key: 'issued',
                        label: this.$t('keys.returnable_packaging.issued'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'returned',
                        label: this.$t('keys.returnable_packaging.returned'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'total_issued',
                        label: this.$t('keys.returnable_packaging.issued_amount'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatPrice
                    },
                    {
                        key: 'total_returned',
                        label: this.$t('keys.returnable_packaging.returned_amount'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatPrice
                    },
                    {
                        key: 'difference',
                        label: this.$t('keys.returnable_packaging.difference'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatPrice
                    }
                ]
            }
        },
        methods: {
            async open(cashRegisterId) {
                const response = await this.$http.get(`api/client/v1/returnable_packaging/status_by_cash_register/${cashRegisterId}`)
                this.items = response.data.items ?? []
                this.modalActive = true
            }
        }
    }
</script>