<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card
                no-body
                class="card-statistics"
            >
                <b-card-header>
                    <b-card-title>{{$t('keys.returnable_packaging.quick_overview')}}</b-card-title>
                    <fa
                        :icon="showDetails ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                        class="ml-auto cursor-pointer"
                        @click="toggleExpand()"
                        size="lg"
                        v-if="statisticsDetailed.length > 1"
                    />
                </b-card-header>
                <b-card-body class="statistics-body">
                    <b-row cols="1" cols-sm="2" cols-md="2" cols-lg="5">
                        <b-col
                            class="py-1"
                            v-for="(item,index) in statisticsItems"
                            :key="index"
                            :class="item.customClass"
                        >
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar
                                        size="48"
                                        :variant="item.color"
                                    >
                                        <feather-icon
                                            size="24"
                                            :icon="item.icon"
                                        />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        <span v-if="item.key === 'available_returnable_packaging'">
                                            {{ parseFloat(item.title).toFixed(2) }}
                                        </span>
                                        <span v-else>
                                            {{ item.title / 100 }}
                                        </span>
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.subtitle }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col xl="12" md="12" sm="12" class="px-0" v-if="showDetails">
                            <div>
                                <CustomTable ref="statisticsDetails" @reloadData="reloadData" :items="statisticsDetailed" :fields="detailedColumnDefs"/>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-card>
                <b-card-header>
                    <b-card-title>{{$t('keys.returnable_packaging.returnable_packaging_status')}}</b-card-title>
                </b-card-header>
                <b-card-body class="statistics-body">
                    <div>
                        <CustomTable :fields="statusColumnDefs" @reloadData="reloadData" api-url="api/client/v1/returnable_packaging/status_by_cash_register"  ref="returnablePackagingStatus">
                            <template #cash_register_status="{item}">
                                <span :class="{
                                    'text-success': getCashRegisterStatus(item) === $t('keys.returnable_packaging.active'),
                                    'text-danger': getCashRegisterStatus(item) === $t('keys.returnable_packaging.inactive')
                                }">
                                    {{ getCashRegisterStatus(item) }}
                                </span>
                            </template>
                            <template #actions="{item}">
                                <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewDetails(item)">
                                    {{ $t('general.details') }}
                                </BButton>
                            </template>
                        </CustomTable>
                        <StatusByCashRegisterModal ref="StatusByCashRegisterModal"/>
                    </div>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import CustomTable from '@/components/CustomTable.vue'
    import StatusByCashRegisterModal from '@/views/ReturnablePackaging/components/StatusByCashRegisterModal.vue'

    import {
        BAvatar,
        BCard,
        BCardBody,
        BCardHeader, BCardText,
        BCardTitle,
        BCol,
        BMedia,
        BMediaAside,
        BMediaBody,
        BRow,
        BOverlay,
        BButton
    } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {
            BCardText,
            BCol,
            BMediaBody,
            BCardHeader,
            BCardTitle,
            BAvatar,
            BRow,
            BMediaAside,
            BMedia,
            BCard,
            BCardBody,
            BOverlay,
            BButton,
            CustomTable,
            StatusByCashRegisterModal
        },
        data() {
            return {
                statisticsDetailed: [],
                cashRegisterStatuses: [],
                statisticsItems:[],
                dataLoaded: false,
                showDetails: false
            }
        },
        watch:{
            'currentLocale'() {
                this.loadData()
            }
        },
        computed: {
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            statisticsItemsDefs() {
                return  [
                    {
                        iconType: 'feather',
                        icon: 'HashIcon',
                        color: 'light-primary',
                        title: '',
                        subtitle: this.$t('keys.returnable_packaging.total_number'),
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_number_of_returnable_packaging'
                    },
                    {
                        iconType: 'feather',
                        icon: 'HashIcon',
                        color: 'light-info',
                        title: '',
                        subtitle: this.$t('keys.returnable_packaging.total_returned') || 'keys.returnable_packaging.default_subtitle',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_number_of_returned_returnable_packaging'
                    },
                    {
                        iconType: 'feather',
                        icon: 'HashIcon',
                        color: 'light-danger',
                        title: '',
                        subtitle: this.$t('keys.returnable_packaging.total_issued'),
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_number_of_issued_returnable_packaging'
                    },
                    {
                        iconType: 'feather',
                        icon: 'HashIcon',
                        color: 'light-success',
                        title: '',
                        subtitle: this.$t('keys.returnable_packaging.total_uncleaned'),
                        customClass: 'mb-2 mb-xl-0',
                        key: 'returnable_packaging_to_clean'
                    },
                    {
                        iconType: 'feather',
                        icon: 'PercentIcon',
                        color: 'light-warning',
                        title: '',
                        subtitle: this.$t('keys.returnable_packaging.available_packaging'),
                        customClass: 'mb-2 mb-xl-0',
                        key: 'available_returnable_packaging'
                    }
                ]
            },
            statusColumnDefs() {
                return [
                    {
                        key: 'cash_register',
                        label: this.$t('keys.returnable_packaging.cash_register_id'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'cash_register.w',
                        permissions: true
                    },
                    {
                        key: 'cash_register_status',
                        label: this.$t('keys.returnable_packaging.cash_register_status'),
                        filter: false,
                        permissions: true,
                        formatter: this.getCashRegisterStatus
                    },
                    {
                        key: 'issued',
                        label: this.$t('keys.returnable_packaging.issued'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatQuantity

                    },
                    {
                        key: 'returned',
                        label: this.$t('keys.returnable_packaging.returned'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true,
                        disableSelect: true
                    }
                ]
            },
            detailedColumnDefs() {
                return [
                    {
                        key: 'packaging_type_code',
                        label: this.$t('keys.returnable_packaging.packaging_code'),
                        filter: true,
                        filterType: 'input'
                    },
                    {
                        key: 'name',
                        label: this.$t('keys.returnable_packaging.name'),
                        filter: true,
                        filterType: 'input'
                    },
                    {
                        key: 'total_number_of_returnable_packaging',
                        label: this.$t('keys.returnable_packaging.total_number'),
                        filter: false,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'total_number_of_issued_returnable_packaging',
                        label: this.$t('keys.returnable_packaging.total_issued'),
                        filter: false,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'total_number_of_returned_returnable_packaging',
                        label: this.$t('keys.returnable_packaging.total_returned'),
                        filter: false,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'returnable_packaging_to_clean',
                        label: this.$t('keys.returnable_packaging.total_uncleaned'),
                        filter: false,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'available_returnable_packaging',
                        label: this.$t('keys.returnable_packaging.available_packaging'),
                        filter: false,
                        formatter: item => parseFloat(item).toFixed(2)
                    }
                ]
            }
        },
        methods: {
            async reloadData() {
                await this.loadData()
                await this.$refs.returnablePackagingStatus.loadData()
            },
            async loadData() {
                this.dataLoaded = false
                try {
                    const response1 = this.$http.get('/api/client/v1/returnable_packaging/statistics')
                    const response2 = this.$http.get('/api/client/v1/cash_registers/online_status')

                    const response = await Promise.all([response1, response2])
                    const statistics = response[0].data?.statistics ?? []
                    this.statisticsDetailed = statistics.detailed_statistics
                    this.cashRegisterStatuses = response[1].data[0].cash_registers ?? []

                    this.statisticsItems = this.statisticsItemsDefs.map(item => ({
                        ...item,
                        title: statistics[item.key]
                    }))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true
                }
            },
            toggleExpand() {
                this.showDetails = !this.showDetails
            },
            getCashRegisterStatus(item) {
                const statusEntry = this.cashRegisterStatuses.find(status => status.cash_register_id === item.id)
                if (statusEntry) {
                    return statusEntry.status === 2 ? this.$t('keys.returnable_packaging.active') : this.$t('keys.returnable_packaging.inactive')
                }
            },
            viewDetails(item) {
                this.$refs.StatusByCashRegisterModal.open(item.id)
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>